import { formatDateTime } from "@converge-collective/common/util";
import {
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLoggedInState } from "~/lib/useLoggedInState";

export function DebugInfo({ sx }: { sx?: SxProps }) {
  const { profile, network } = useLoggedInState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cellMaxWidth = isMobile ? 200 : 400;

  const profileFirestoreUrl =
    profile &&
    `https://console.firebase.google.com/u/0/project/converge-mt/firestore/data/~2Fprofiles~2F${profile.id}`;

  const networkFirestoreUrl =
    network &&
    `https://console.firebase.google.com/u/0/project/converge-mt/firestore/data/${encodeURIComponent(network.ref.path)}`;

  const rows = [
    ...(profile
      ? [
          {
            label: "User (Firestore)",
            value: (
              <a target="_blank" href={profileFirestoreUrl}>
                {profile.id}
              </a>
            ),
          },
        ]
      : []),
    ...(network
      ? [
          {
            label: "Network (Firestore)",
            value: (
              <a target="_blank" href={networkFirestoreUrl}>
                {network.name}
              </a>
            ),
          },
        ]
      : []),
    {
      label: "GitHub Actor",
      value: (
        <>
          <a
            target="_blank"
            href={`https://github.com/${process.env.GITHUB_ACTOR}`}
          >
            {process.env.GITHUB_ACTOR}
          </a>
        </>
      ),
    },
    { label: "GitHub Branch", value: process.env.GITHUB_HEAD_REF },
    {
      label: "GitHub Commit",
      value: (
        <>
          <a
            href={`https://github.com/converge-collective/web/commit/${process.env.GITHUB_SHA}`}
          >
            {process.env.GITHUB_SHA}
          </a>
        </>
      ),
    },
    { label: "GitHub PR Title", value: process.env.GITHUB_PR_TITLE },
    {
      label: "GitHub PR URL",
      value: (
        <>
          <a target="_blank" href={process.env.GITHUB_PR_URL}>
            {process.env.GITHUB_PR_URL}
          </a>
        </>
      ),
    },
    {
      label: "GitHub PR Updated At",
      value:
        process.env.GITHUB_PR_UPDATED_AT &&
        formatDateTime(new Date(process.env.GITHUB_PR_UPDATED_AT)),
    },
  ];

  return (
    <Paper sx={sx}>
      <Table sx={{ width: "100%" }}>
        <TableBody>
          {rows.map(({ label, value }) => (
            <TableRow key={label}>
              <TableCell>
                <b>{label}</b>
              </TableCell>
              <TableCell sx={{ maxWidth: cellMaxWidth, overflow: "scroll" }}>
                {value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
