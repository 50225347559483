import { WithRef } from "@converge-collective/common/models/Base";
import { Group as IGroup } from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import { Groups, Lock } from "@mui/icons-material";
import Group from "@mui/icons-material/Group";
import { Chip, CircularProgress, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { MenuItem, SubMenu, menuClasses } from "react-pro-sidebar";
import { useMyGroups } from "~/hooks/useMyGroups";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { groupDetailRoute, groupsRoute } from "~/routes";

export function GroupsNav({ isOpen }) {
  const { network } = useLoggedInState();

  const { groups: myGroups, status, unreadMessages } = useMyGroups();

  if (!network || !myGroups || isEmpty(myGroups)) {
    return (
      <Link href={groupsRoute(network?.slug || "")} legacyBehavior>
        <MenuItem icon={<Groups />}>Groups</MenuItem>
      </Link>
    );
  }

  return (
    <SubMenu
      defaultOpen
      icon={<Groups />}
      label="Groups"
      rootStyles={{
        ["." + menuClasses.subMenuContent]: {
          inset: "92px auto auto 0 !important",
          ...(!isOpen && {
            overflow: "scroll",
            maxHeight: "700px",
            overflowX: "hidden",
          }),
        },
      }}
    >
      {(status === "loading" || !myGroups) && (
        <MenuItem>
          <CircularProgress size={20} />
        </MenuItem>
      )}

      {status === "error" && !myGroups && (
        <MenuItem>Something Went Wrong.</MenuItem>
      )}

      {status === "success" &&
        myGroups?.length > 0 &&
        myGroups.map((group) => {
          const unreadCount = unreadMessages?.[group.id] ?? 0;
          return (
            <GroupMenuItem
              key={group.id}
              group={group}
              network={network}
              unreadCount={unreadCount}
            />
          );
        })}

      <Link href={groupsRoute(network?.slug || "")} legacyBehavior>
        <MenuItem icon={<Groups color="primary" />}>All Groups</MenuItem>
      </Link>
    </SubMenu>
  );
}

function GroupMenuItem({
  group,
  network,
  unreadCount,
}: {
  group: WithRef<IGroup>;
  network: WithRef<Network>;
  unreadCount: number;
}) {
  const router = useRouter();

  const privateIcon = (
    <Tooltip title="Private Group">
      <Lock color="primary" />
    </Tooltip>
  );

  const photoIcon = (
    <img
      style={{
        width: 30,
        height: 30,
        objectFit: "cover",
        borderRadius: 5,
      }}
      alt={group.name}
      src={group.photoUrl ?? ""}
    />
  );

  const icon = group.isPrivate ? (
    privateIcon
  ) : group.photoUrl ? (
    photoIcon
  ) : (
    <Group />
  );

  return (
    <MenuItem
      icon={icon}
      onClick={() => router.push(groupDetailRoute(network.slug, group.slug))}
      key={group.id}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      suffix={
        unreadCount > 0 ? (
          <Chip label={unreadCount} size="small" color="primary" />
        ) : null
      }
    >
      <Tooltip title={group.name}>
        <Typography
          sx={{
            fontWeight: unreadCount > 0 ? "bold" : "normal",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "90%",
          }}
          flex={1}
          display={"inline-block"}
        >
          {group.name}
        </Typography>
      </Tooltip>
    </MenuItem>
  );
}
