import { NetworkMembership } from "@converge-collective/common/models/Network";

import {
  collectionGroup,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useRouter } from "next/router";
import { memo, useCallback, useEffect } from "react";
import { useFirestore } from "reactfire";
import { converters } from "~/lib/converter";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { newsFeedRoute } from "~/routes";

/**
 * When a user is logged in they should also have a profile, unless they just
 * signed up and haven't yet created a profile. This component tracks the user's
 * profile and persists it in a global store.
 */
export const ProfileState = memo(function ProfileState(): React.ReactElement {
  const firestore = useFirestore();
  const { user, setProfile, profile } = useLoggedInState();
  const router = useRouter();
  const currentPath = router.pathname;
  const networkSlug = router.query?.network;
  const networkMembership = profile?.currentNetworkMembership;
  const getTimezoneString = () =>
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(
    /*
     * set timezone on the profile only if it isn't set.
     * that way users can choose a different timezone than the one they're in.
     */
    function setProfileTimezone() {
      if (profile && !profile.timezone) {
        const timezone = getTimezoneString();
        console.log("ProfileState updating user timezone", {
          profile,
          timezone,
        });
        updateDoc(profile.ref, { timezone });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile?.id]
  );

  useEffect(
    /**
     * as soon as we have a user, subscribe to user profile and persist changes
     * in the global store. NOTE: this is where `profile` is initially
     * populated, so we can't use `profile.ref` for the initial load
     */
    function subscribeToProfile() {
      if (user?.uid) {
        const profileRef = doc(firestore, "profiles", user.uid).withConverter(
          converters.profile.read
        );
        const profileUnsub = onSnapshot(
          profileRef.withConverter(converters.profile.read),
          (doc) => {
            const newProfile = doc.data();
            console.log("ProfileState obtained new profile from snapshot", {
              newProfile,
            });
            setProfile(newProfile);
          }
        );
        return profileUnsub;
      } else {
        // if there's no logged in user then clear the profile
        setProfile(undefined);
      }
    },
    [user?.uid, setProfile, firestore]
  );

  /**
   * Attempts to return a `currentNetworkMembership` for the current user
   * profile.
   *
   * If a user loads a network url, the url should update the profile
   * currentNetworkMembership to match the network represented by the url slug.
   * Make sure this doesn't result in race conditions.
   * Only set the currentNetworkMembership if the window is focused. That way
   * tabs won't fight over the currentNetworkMembership.
   *
   * Returns `undefined` if:
   * - the profile is not yet loaded
   * - or the profile is loaded but the user is not a member of the network
   */
  const setCurrentNetworkMembership = useCallback(async (): Promise<
    NetworkMembership | undefined
  > => {
    // we can't do anything if the profile isn't available,
    if (!profile) {
      return undefined;
    }

    console.log("ProfileState setCurrentNetworkMembership", {
      profile,
      networkSlug,
    });

    // if the user already has one, return it
    if (profile.currentNetworkMembership) {
      return profile.currentNetworkMembership;
    }

    // and if the window isn't focused, don't set the currentNetworkMembership
    // to avoid different tabs fighting over the currentNetworkMembership
    const windowFocused = document.hasFocus();
    if (!windowFocused) {
      return undefined;
    }

    // if the user doesn't have a current network set yet, look up their network
    // memberships and set the first one as current
    if (!profile.currentNetworkMembership) {
      let currentNetworkMembership: NetworkMembership | undefined = undefined;

      console.log("ProfileState updating profile currentNetworkMembership", {
        profile,
      });

      if (networkSlug) {
        // if url already contained the network, persist it
        const networkMembershipsQuery = query(
          collectionGroup(firestore, "members").withConverter(
            converters.networkMembership.read
          ),
          where("profileRef", "==", profile.ref),
          where("network.slug", "==", networkSlug)
        );

        const networkMemberships = await getDocs(networkMembershipsQuery);

        if (!networkMemberships.empty) {
          currentNetworkMembership = networkMemberships.docs[0].data();
        }
      } else {
        // query networks and set the first one as current
        const networkMembershipsQuery = query(
          collectionGroup(firestore, "members").withConverter(
            converters.networkMembership.read
          ),
          where("profileRef", "==", profile.ref)
        );
        const networkMemberships = await getDocs(networkMembershipsQuery);
        if (!networkMemberships.empty) {
          currentNetworkMembership = networkMemberships.docs[0].data();
        }
      }

      // Note: we could conditionally set the currentNetworkMembership only if
      // it's different than the current one
      if (currentNetworkMembership) {
        await updateDoc(profile.ref, { currentNetworkMembership });
        return currentNetworkMembership;
      } else {
        console.warn("No networks available for this user!");
        return undefined;
      }
      // TODO handle the case where the user doesn't belong to any
      // networks. Show them a friendly error page asking them to talk to
      // their Admin and ask for an invite or something.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, networkSlug, profile?.id]);

  useEffect(
    function redirectToNewsfeed() {
      const redirectIfRoot = async () => {
        // Checks if already has membership, use it else set first membership as current
        const membership =
          !!networkMembership && networkMembership.network
            ? networkMembership
            : await setCurrentNetworkMembership();

        // If membership exists and path is root
        if (membership && router.asPath === "/") {
          router.push(newsFeedRoute(membership.network.slug));
        }
      };

      if (user) {
        redirectIfRoot();
      }
    },
    [setCurrentNetworkMembership, user, networkMembership, currentPath]
  );
  // no UI - just hooks and state management
  return <></>;
});
