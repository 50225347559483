import { Button } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useFirebaseApp } from "reactfire";

export const LoggedOutNav = ({
  setSignInVisible,
  setCreateAccountVisible,
}: {
  setSignInVisible: (b: boolean) => void;
  setCreateAccountVisible: (b: boolean) => void;
}): React.ReactElement => {
  const app = useFirebaseApp();
  return (
    <>
      <div style={{ marginLeft: "auto" }} />
      <Button onClick={() => setCreateAccountVisible(true)}>
        Create account
      </Button>
      <Button
        sx={{ ml: 1 }}
        onClick={() => {
          setSignInVisible(true);
          // const params = {};
          // must be called client side only
          const analytics = getAnalytics(app);
          logEvent(analytics, "sign_in_click");
        }}
      >
        Sign in
      </Button>
    </>
  );
};
