import { AppBar, Link as MuiLink, Skeleton, Toolbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { sendEmailVerification } from "firebase/auth";
import { PerformanceTrace, getPerformance, trace } from "firebase/performance";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { memo, useEffect, useState } from "react";
import { useAuth, useSigninCheck } from "reactfire";
import { useIsMobile } from "~/hooks/mobile";
import { useGlobalState } from "~/lib/globalHeaderState";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { newsFeedRoute } from "~/routes";
import { LoggedOutNav } from "./LoggedOutNav";
import { MarketingNav } from "./MarketingNav";
import { NetworkSponsor } from "./NetworkSponsor";
import CreateAccount from "./auth/CreateAccount";
import { SignInDialog } from "./auth/SignInDialog";
import BrandLogo from "./common/BrandLogo";

// dynamically import LoggedInNav because it includes many other deps like
// creating an event which brings google maps places and the rich text editor
const DynamicLoggedInNav = dynamic(() => import("./LoggedInNav"), {
  ssr: false,
});

export const Header = memo(function Header(): React.ReactElement {
  const [loginTrace, setLoginTrace] = useState<PerformanceTrace>();

  const auth = useAuth();
  useEffect(() => {
    // must be initialized client side only
    const perf = getPerformance();
    // only set loginTrace once
    console.log("Header set loginTrace", { loginTrace });
    if (!loginTrace) {
      const t = trace(perf, "AUTH_USER_LOADED");
      setLoginTrace(t);
      t.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = useRouter();
  const { status, data: signInCheckResult } = useSigninCheck();
  const { asPath, query: urlQuery } = router;

  const isHarvest = router.asPath === "/harvest-church";
  // console.log("Header", { isHarvest, pathname: router.asPath });

  useEffect(() => {
    const onRouteChangeStart = () => {
      // disabled to see if it improves performance
      // clearFirestoreCache();
    };
    router.events.on("routeChangeStart", onRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [router.events]);
  const [createAccountVisible, setCreateAccountVisible] = useGlobalState(
    "isCreateAccountOverlayOpen"
  );
  const [signInVisible, setSignInVisible] = useGlobalState(
    "isSignInOverlayOpen"
  );

  // allow query param to open create account overlay
  useEffect(() => {
    console.log("Header query params", { urlQuery });
    if ("ca" in urlQuery) {
      setCreateAccountVisible(true);
    }
  }, [urlQuery, setCreateAccountVisible]);

  const { currentUser: user } = auth;
  const isUnverified = user && !user.emailVerified;
  console.log("Header", { isUnverified, user });

  const { network } = useLoggedInState();
  console.log("Header network", { network });

  // we only show network-wide sponsors on the newsfeed
  const isFeed = network && asPath == newsFeedRoute(network.slug);

  useEffect(() => {
    // user is loaded, so stop the trace
    if (user && loginTrace) {
      console.log("Header stop trace loginTrace", { loginTrace });
      loginTrace.stop();
      // prevent this block from firing again
      setLoginTrace(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid, loginTrace]);

  const isMobile = useIsMobile();

  console.count("Render Header");

  // TODO remove all this junk completely someday
  const marketingPaths = [
    ...["/about", "/contact", "/billings", "/billings/sponsorship"],
    ...(user ? [] : ["/billings/events", "/billings/groups"]),
  ];
  const isMarketing = marketingPaths.find((path) => asPath.startsWith(path));

  const networkSlug = "billings";
  // const marketingNav = marketingNavItems(networkSlug, undefined);
  // const hideMainHeaderNav = false;

  const [resendDisabled, setResendDisabled] = useState(false);

  const resendVerification = async () => {
    if (!user) {
      console.error("No user to resend verification email to");
      return;
    }
    await sendEmailVerification(user);
    setResendDisabled(true);
  };

  const userNavIsLoading =
    status === "loading" || (!!signInCheckResult?.signedIn && !user);

  /** @deprecated
   * eventually remove this once we confirm we don't need it
   */
  // const _renderSubNav = () =>
  //   !user && (
  //     <Alert
  //       icon={<HikingIcon fontSize="inherit" />}
  //       severity="info"
  //       sx={{
  //         width: "100%",
  //         mt: 0,
  //         px: 4,
  //         py: 0.2,
  //         "& .MuiAlert-message": { width: "100%" },
  //       }}
  //     >
  //       <Stack direction="row" justifyContent="space-between">
  //         {!isMarketing ? (
  //           <Typography variant="body2">
  //             Do more of the things you love with the people that share your
  //             passions in Billings. <Link href="/billings">Learn more.</Link>
  //           </Typography>
  //         ) : (
  //           <Stack direction="row">
  //             {marketingNav.map(({ url, onClick, label }) => (
  //               <Box key={url} sx={{ mx: 1 }}>
  //                 <Typography variant="body2">
  //                   {url ? (
  //                     <Link
  //                       href={url}
  //                       key={url}
  //                       style={{
  //                         textDecoration: "none",
  //                         color: "#000",
  //                       }}
  //                     >
  //                       {label}
  //                     </Link>
  //                   ) : (
  //                     <Button onClick={onClick}>{label}</Button>
  //                   )}
  //                 </Typography>
  //               </Box>
  //             ))}
  //           </Stack>
  //         )}
  //       </Stack>
  //     </Alert>
  //   );
  // const marginBottom = hideMainHeaderNav ? 4 : isMarketing ? 16 : 13;

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          m: 0,
          p: 0,
          background: "white",
          // use custom box shadow instead of elevation={1} so we can get shadow
          // only on the bottom. use this generator to tweak it:
          // https://htmlcssfreebies.com/box-shadow-bottom/
          boxShadow: "0 6px 17px -17px rgba(0,0,0,0.45)",
        }}
      >
        <Toolbar sx={{ px: 2 }}>
          {/* in mobile or logged-out view render the logo since there won't be
            a sidebar */}
          {(!user || isMobile) && (
            <BrandLogo
              height={38}
              width={40}
              sx={{ marginRight: "10px", marginTop: "5px" }}
            />
          )}
          {userNavIsLoading ? (
            <>
              <div style={{ marginLeft: "auto" }} />
              <Skeleton
                suppressHydrationWarning
                variant="circular"
                width={40}
                height={40}
              />
            </>
          ) : isMarketing && !user ? (
            <MarketingNav
              showLeftNav={true}
              showAuthNav={true}
              networkSlug={networkSlug}
              setSignInVisible={setSignInVisible}
              setCreateAccountVisible={setCreateAccountVisible}
            />
          ) : user ? (
            <DynamicLoggedInNav />
          ) : isHarvest ? null : (
            <LoggedOutNav
              setSignInVisible={setSignInVisible}
              setCreateAccountVisible={setCreateAccountVisible}
            />
          )}
        </Toolbar>
        {/*  disable the verify banner since people are having trouble verifying */}
        {isUnverified && false && (
          <Alert severity="info">
            <b>Thank you for signing up!</b> Please verify your email address.{" "}
            {resendDisabled ? (
              <>✓ Sent!</>
            ) : (
              <MuiLink
                component="button"
                disabled={resendDisabled}
                onClick={resendVerification}
              >
                Resend email.
              </MuiLink>
            )}
          </Alert>
        )}
      </AppBar>

      {/* Modals: */}

      {createAccountVisible && (
        <CreateAccount
          isOpen={createAccountVisible}
          handleClose={() => setCreateAccountVisible(false)}
        />
      )}

      {signInVisible && (
        <SignInDialog
          isOpen={signInVisible}
          handleClose={() => setSignInVisible(false)}
        />
      )}

      {/* Render network sponsors only on the newsfeed */}
      {network && isFeed && <NetworkSponsor network={network} />}
    </>
  );
});
