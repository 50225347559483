import {
  AdminPanelSettings,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  SquareSharp,
} from "@mui/icons-material";

import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  IconButton,
  Stack,
  SvgIconProps,
  Tooltip,
  lighten,
  useTheme,
} from "@mui/material";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { memo } from "react";
import { GrFlagFill } from "react-icons/gr";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import BrandLogo from "~/components/common/BrandLogo";
import { useIsMobile } from "~/hooks/mobile";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { useSidebarNavState } from "~/lib/useSidebarNavState";
import {
  NavItem,
  competitionNav,
  mainNav,
  networkAdminNav,
  newsFeedRoute,
} from "~/routes";
import { GroupsNav } from "./GroupsNav";

const CollapseIcon = KeyboardDoubleArrowLeft;
const ExpandIcon = KeyboardDoubleArrowRight;

export const SidebarNav = memo(function SidebarNavMemo({
  allowMobile = false,
  showExpandCollapse = true,
}: {
  allowMobile?: boolean;
  showExpandCollapse?: boolean;
}): React.ReactElement {
  const { isNetworkAdmin, network, user, profile } = useLoggedInState();
  const hasNetworkMembership = Boolean(profile?.currentNetworkMembership);

  const router = useRouter();
  const theme = useTheme();

  const networkNav = network ? mainNav(network) : undefined;
  const compNav = network ? competitionNav(network) : [];
  const adminNav = isNetworkAdmin && network ? networkAdminNav(network) : [];
  const isMobile = useIsMobile();
  const { expanded, toggle } = useSidebarNavState();

  const renderIcon = (
    label: string,
    icon?: React.ComponentType<SvgIconProps>,
    iconColor: string = "inherit"
  ) => {
    return (
      <Tooltip title={label} placement="right" arrow>
        <span style={{ lineHeight: 0 }}>
          {icon ? (
            React.createElement(icon, { style: { color: iconColor } })
          ) : (
            <SquareSharp />
          )}
        </span>
      </Tooltip>
    );
  };

  const isActive = (url: string) => router.asPath === url;

  /**
   * TODO consider removing support for non-URL nav items
   */
  const renderNavItem = (
    // additional props
    { iconColor }: { iconColor: string },
    // nav item
    { icon, url, onClick, label }: NavItem
  ) =>
    url ? (
      <Link key={url} href={url} legacyBehavior>
        <MenuItem
          active={isActive(url)}
          icon={renderIcon(label, icon, iconColor)}
          key={url}
        >
          {label}
        </MenuItem>
      </Link>
    ) : (
      <MenuItem
        icon={renderIcon(label, icon, iconColor)}
        key={url}
        onClick={onClick}
      >
        {label}
      </MenuItem>
    );

  const expandCollapse = showExpandCollapse ? (
    <Stack
      sx={{
        position: "sticky",
        top: "calc(100vh - 46px)",
        py: 0.1,
        pr: 0,
        borderTop: "1px solid #e0e0e0",
        width: "100%",
      }}
      direction="row"
      justifyContent="right"
      spacing={2}
    >
      <IconButton
        sx={{
          fontSize: 30,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={toggle}
        color="primary"
      >
        {!expanded ? (
          <ExpandIcon fontSize="inherit" />
        ) : (
          <CollapseIcon fontSize="inherit" />
        )}
      </IconButton>
    </Stack>
  ) : null;

  // if:
  // - the user isn't signed in
  // - or it's mobile
  // - on the home page
  // - or the user hasn't joined any networks yet
  // then don't show the sidebar
  if (!user || (!allowMobile && isMobile) || router.pathname === "/") {
    return <></>;
  }

  const renderNavItemDefault = (navItem: NavItem) =>
    renderNavItem({ iconColor: "inherit" }, navItem);
  const renderNavItemAdmin = (navItem: NavItem) =>
    renderNavItem({ iconColor: theme.palette.primary.main }, navItem);

  return (
    <>
      <div
        style={{
          zIndex: 999,
          position: "sticky",
          top: 0,
          backgroundColor: "white",
        }}
      >
        <Sidebar
          width={"300px"}
          collapsed={!expanded}
          rootStyles={{
            position: "sticky",
            top: 0,
            boxShadow: "6px 0px 20px -20px rgba(0,0,0,0.45)",
          }}
          style={
            {
              // position: "sticky",
              // top: 0,
              // height: "unset !important",
            }
          }
          backgroundColor="white"
        >
          <Box
            sx={{
              backgroundColor: "white",
              height: "(100vh)",
            }}
          >
            <Box
              sx={{
                maxHeight: `calc(100vh - ${showExpandCollapse ? "50px" : "0"})`,
                overflowY: "auto",
                paddingTop: "20px",
              }}
            >
              <Stack
                sx={{ mb: 2 }}
                justifyContent="center"
                direction="row"
                spacing={2}
              >
                <BrandLogo sx={{ p: 1.2 }} />
              </Stack>
              {/* Competition subnav */}
              <Menu
                renderExpandIcon={({ open }) => {
                  return (
                    <div style={{ marginTop: 8 }}>
                      {open ? (
                        <ExpandMore sx={{ fontSize: 22 }} />
                      ) : (
                        <ChevronRight sx={{ fontSize: 22 }} />
                      )}
                    </div>
                  );
                }}
                menuItemStyles={{
                  button: ({ level, active }) => {
                    if (level === 0 || level === 1) {
                      return {
                        backgroundColor: active
                          ? lighten(theme.palette.primary.light, 0.9)
                          : undefined,
                        color: active ? "inherit" : "inherit",
                        "&:hover": {
                          backgroundColor: theme.palette.primary[50],
                          color: "inherit",
                        },
                      };
                    }
                  },
                }}
              >
                {hasNetworkMembership && (
                  <>
                    {network && networkNav && (
                      <>
                        {renderNavItem(
                          { iconColor: "inherit" },
                          {
                            url: newsFeedRoute(network.slug),
                            label: "Feed",
                            icon: dynamic(
                              () => import("@mui/icons-material/Home")
                            ),
                          }
                        )}

                        <GroupsNav isOpen={expanded} />
                      </>
                    )}
                    {networkNav?.map(renderNavItemDefault)}
                    {networkNav && compNav && (
                      <SubMenu
                        defaultOpen
                        icon={<GrFlagFill />}
                        label="Competition"
                      >
                        {compNav.map(renderNavItemDefault)}
                      </SubMenu>
                    )}
                    {isNetworkAdmin && (
                      <SubMenu
                        rootStyles={{}}
                        icon={
                          <Tooltip
                            title="Network Admins Only Area"
                            arrow
                            placement="right"
                          >
                            <AdminPanelSettings color="primary" />
                          </Tooltip>
                        }
                        label="Network Admin"
                      >
                        {adminNav.map(renderNavItemAdmin)}
                      </SubMenu>
                    )}
                  </>
                )}
              </Menu>
            </Box>
          </Box>
        </Sidebar>
        {expandCollapse}
      </div>
    </>
  );
});
