import { LiteProfile } from "@converge-collective/common/models/Profile";
import { UserClaims } from "@converge-collective/common/models/User";
import {
  User,
  getAuth,
  getRedirectResult,
  signInWithCustomToken,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import cookie from "js-cookie";
import { useRouter } from "next/router";
import { memo, useEffect } from "react";
import { useFirestore } from "reactfire";
import { converters } from "~/lib/converter";
import { useLoggedInState } from "~/lib/useLoggedInState";

const userCookieName = "auth";
const userCookieExpires = 1;

const updateCookie = async (firebaseUser: User) => {
  console.log("update auth cookie", firebaseUser);
  // any time the token is updated store it in a cookie
  const token = await firebaseUser.getIdToken();
  const { uid, email } = firebaseUser;
  const user = {
    uid,
    token,
    email: email,
  };
  cookie.set(userCookieName, JSON.stringify(user), {
    expires: userCookieExpires,
  });
};

const removeCookie = () => {
  cookie.remove(userCookieName);
};

/**
 * Handle changes to the user's Firebase auth state.
 * Handle Firebase `getRedirectResult` when the user is redirected back to the
 * app after signing in through social or oauth providers.
 *
 * When a user logs in, we:
 *
 * 1. Update a cookie with their auth token. This allows us to authenticate the
 * user server-side when accessing API routes.
 *
 * When a user logs out, we:
 *
 * 1. Delete their auth cookie
 */
export const UserState = memo(function UserState(): React.ReactElement {
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore();
  const router = useRouter();
  // fbct = firebase custom token
  const { fbct } = router.query;

  const { setUser, setUserClaims, network, profile } = useLoggedInState();

  console.count("UserState");

  useEffect(
    function loginWithCustomToken() {
      if (fbct) {
        console.log("loginWithCustomToken", { fbct });
        signInWithCustomToken(auth, fbct as string)
          .then((firebaseUser) => handleUserLoginOrUpdate(firebaseUser.user))
          .catch(console.error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fbct]
  );

  useEffect(
    function updateIdToken() {
      // when needsAuthRefresh is true update the token and set it back to false
      if (user && profile?.needsAuthRefresh) {
        console.log("refreshing id token for profile", { profile });
        user.getIdToken(true);
        updateDoc(profile.ref, {
          needsAuthRefresh: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.uid, profile?.needsAuthRefresh]
  );

  const setProfileState = async (uid: string) => {
    const profileRef = doc(firestore, `profiles/${uid}`);

    await updateDoc(profileRef, {
      lastSeenAt: new Date(),
    });
  };
  /** Handle for onIdTokenChanged and onAuthStateChanged */
  const handleUserLoginOrUpdate = async (firebaseUser: User) => {
    setUser(firebaseUser || undefined);
    if (firebaseUser) {
      console.log("onIdTokenChanged, updated cookie");
      updateCookie(firebaseUser);
      // as soon as the user logs in save the user claims in global state
      const { claims } = await firebaseUser.getIdTokenResult();
      setUserClaims(claims as UserClaims);
      await setProfileState(firebaseUser.uid);
      console.log("Updating profile lastSeenAt", { profile });
    } else {
      // delete the cookie to ensure they aren't still signed on on the
      // server
      removeCookie();
      setUserClaims(undefined);
    }
  };

  /** Handle onIdTokenChanged */
  useEffect(() => {
    return auth.onIdTokenChanged(handleUserLoginOrUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Handle onAuthStateChanged */
  useEffect(function setupAuthStateChangeListener() {
    return auth.onAuthStateChanged(handleUserLoginOrUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** fetch claims any time the user or network change */
  useEffect(
    function fetchClaims() {
      const user = auth.currentUser;
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          setUserClaims(claims as UserClaims);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.uid, network?.id]
  );

  /**
   * refresh the token every 10 minutes
   * this keeps the user logged in, and refreshes their claims as well
   */
  useEffect(function refreshIdToken() {
    const tenMinutes = 10 * 60 * 1000;
    const handle = setInterval(async () => {
      const user = auth.currentUser;
      try {
        if (user) await user.getIdToken(true);
      } catch (e) {
        console.error("error refreshing token", { e });
      }
    }, tenMinutes);
    // clean up setInterval
    return () => clearInterval(handle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Handle social redirects */
  useEffect(() => {
    // social sign in produces a redirect. in that case, make sure they have a
    // profile - if not, create it, and send them to onboarding flow
    getRedirectResult(auth).then(async (result) => {
      console.log("getRedirectResult", { result });
      if (!result) return;

      const { user } = result;
      if (user) {
        // make sure they have a profile
        const { uid } = user;
        const profileRef = doc(firestore, "profiles", uid).withConverter(
          converters.profile.read
        );
        const profileSnap = await getDoc(profileRef);
        const profile = profileSnap.data();
        // NOTE: it might exist, because of the timezone updater, so also check
        // whether name is set (Note: we could use `zod` to verify it's a full
        // profile if we wanted a better guarantee)
        if (
          !profileSnap.exists() ||
          !profile ||
          (!profile.firstName && !profile.lastName)
        ) {
          console.log(
            "getRedirectResult: creating profile for new user via social sign in",
            { uid, profile }
          );
          const name = user.displayName || "";
          const [firstName, lastName] = name.split(" ", 2);
          const photoURL = user.photoURL;
          // create a partial profile for new user that just signed in via social
          const partialProfile: Partial<LiteProfile> = {
            createdAt: new Date(),
            id: uid,
            name,
            firstName,
            lastName,
            ...(photoURL && { photoURL }),
          };
          await setDoc(profileRef, partialProfile, { merge: true });
          router.push("/onboarding/account");
        } else {
          console.log("getRedirectResult: profile already exists for user", {
            uid,
            profileSnap,
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});
