import { createGlobalState } from "react-hooks-global-state";

export type GlobalHeaderState = {
  isSignInOverlayOpen: boolean;
  isCreateAccountOverlayOpen: boolean;
};

const initState: GlobalHeaderState = {
  isSignInOverlayOpen: false,
  isCreateAccountOverlayOpen: false,
};

export const { useGlobalState, getGlobalState, setGlobalState } =
  createGlobalState(initState);

export const setSignInOverlayOpen = (isOpen: boolean) =>
  setGlobalState("isSignInOverlayOpen", isOpen);

export const setCreateAccountOverlayOpen = (isOpen: boolean) =>
  setGlobalState("isCreateAccountOverlayOpen", isOpen);
