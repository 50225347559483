import {
  NativeBridgeMessageType,
  NativeBridgeMessageTypes,
} from "@converge-collective/common/models/NativeAppBridge";
import { Button, Stack } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { isEmpty } from "lodash";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import {
  HydratedLoggedInState,
  withLoggedInState,
} from "~/lib/withLoggedInState";

declare const window: any;
declare const document: any;

export const hasRN = (): boolean => window?.ReactNativeWebView;

export const postEnableDebug = (): void => {
  if (hasRN()) {
    postToRN(NativeBridgeMessageTypes.EnableDebug);
  }
};

export const postGetWorkouts = (): void => {
  if (hasRN()) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        converge: true,
        messageType: NativeBridgeMessageTypes.GetWorkouts,
      })
    );
  }
};

export const postToRN = async (
  messageType: NativeBridgeMessageType,
  extra: Record<string, any> = {}
): Promise<void> => {
  if (!window.ReactNativeWebView) {
    // console.warn("window.ReactNativeWebView is undefined");
    return;
  }
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ messageType, ...extra, converge: true })
  );
};

export const ReactNativeBridge = withLoggedInState(
  function ReactNativeBridge({
    profile,
  }: HydratedLoggedInState): React.ReactElement {
    const router = useRouter();

    const renderCountRef = useRef(0);
    useEffect(() => {
      renderCountRef.current++;
    });

    // always enable debug for Trevor Hartman's account
    const isDebugEnabled = profile?.isDebugEnabled === true;
    const [debug, setDebug] = useState("");

    const savePushToken = async (expoPushToken: string) => {
      if (!isEmpty(expoPushToken)) {
        appendDebug("Saving push notification token for user", {
          expoPushToken,
          uid: profile.id,
        });
        console.log("setting token", { expoPushToken });
        await updateDoc(profile.ref, { expoPushToken });
      } else {
        appendDebug("Skipping save, empty token", {
          expoPushToken,
          uid: profile.id,
        });
      }
    };

    const appendDebug = (
      text: string,
      obj: Record<string, any> | undefined = undefined
    ) =>
      setDebug(
        (oldDebug) =>
          text +
          (obj ? "\n" + JSON.stringify(obj, null, 2) : "") +
          "\n---\n" +
          oldDebug
      );

    useEffect(() => {
      console.count("adding addEventListener for RN");
      const onMessage = async (message: MessageEvent) => {
        const { data } = message;
        // ensure the event came from the converge app
        if (data.converge) {
          appendDebug(
            "message type: " +
              data.messageType +
              "\n" +
              JSON.stringify(message, null, 2) +
              "\n" +
              JSON.stringify(data, null, 2)
          );
          console.log("RNB converge MessageEvent", { message });

          // TODO - keep this but we can remove all the other handlers since we
          // simplified the expo native app August 2023
          if (
            data.messageType ===
            NativeBridgeMessageTypes.SavePushNotificationToken
          ) {
            const { expoPushToken } = data;
            if (!expoPushToken || isEmpty(expoPushToken)) {
              console.error("Empty token", { expoPushToken });
              appendDebug("Empty token", { expoPushToken });

              return;
            }
            appendDebug("Received token from RN update!!", {
              expoPushToken,
              uid: profile.id,
            });
            savePushToken(expoPushToken);
          }

          if (data.messageType === NativeBridgeMessageTypes.Nav) {
            // set hash urls manually - nextjs router prevents correct handling of
            // hashes by the `useHash` hook
            if (data.url.startsWith("#")) {
              location.hash = data.url;
            } else {
              router.push(data.url);
            }
          }
        }
      };
      // ios needs the listener on window
      window.addEventListener("message", onMessage);
      // android needs the listener on document
      document.addEventListener("message", onMessage);
      return () => {
        window.removeEventListener("message", onMessage);
        document.removeEventListener("message", onMessage);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div>
        {isDebugEnabled && (
          <>
            <Stack spacing={2}>
              <div>
                <Button
                  onClick={() => {
                    router.refresh();
                  }}
                >
                  Refresh Page
                </Button>
              </div>
              <div>Git SHA: {process.env.GITHUB_SHA} </div>
              <div>Render count: {renderCountRef.current}</div>
              <Button
                onClick={() => {
                  postToRN(NativeBridgeMessageTypes.PromptAllowNotifications);
                }}
                variant="contained"
              >
                Allow Notifications
              </Button>
              <Button
                onClick={() => postToRN(NativeBridgeMessageTypes.EnableDebug)}
                variant="contained"
              >
                Enable Debug
              </Button>
            </Stack>

            <pre> {debug} </pre>
          </>
        )}
      </div>
    );
  },
  { showLoader: false }
);
