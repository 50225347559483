import { Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { hasRN } from "./ReactNativeBridge";

const width = 200;
const height = "auto";

// native resolution is 1080 x 312

export const AppleAppStoreButton = (): React.ReactElement => (
  <Button href="https://apps.apple.com/us/app/converge-health/id1562855459?itsct=apps_box_badge&amp;itscg=30200">
    <img
      style={{ margin: 8, width, height }}
      src="https://firebasestorage.googleapis.com/v0/b/converge-mt-prod.appspot.com/o/static%2Fapple-app-store.png?alt=media&token=9d54eacf-d763-416f-9e1e-3fca53f52feb"
      alt="Download on the App Store"
    />
  </Button>
);
export const GoogleAppStoreButton = (): React.ReactElement => (
  <Button href="https://play.google.com/store/apps/details?id=is.converge.converge&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    <img
      style={{ margin: 8, width, height }}
      src="https://firebasestorage.googleapis.com/v0/b/converge-mt-prod.appspot.com/o/static%2Fgoogle-play-store.png?alt=media&token=28eb7522-093d-4704-8838-90ac68c0e6a6"
      alt="Get it on Google Play"
    />
  </Button>
);

export const AppButtons = (): React.ReactElement => {
  const [isHidden, setIsHidden] = React.useState(false);
  useEffect(() => setIsHidden(hasRN()), []);

  return isHidden ? (
    <></>
  ) : (
    <Stack
      sx={{ py: 0.5, flexFlow: "wrap" }}
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      <AppleAppStoreButton />
      <GoogleAppStoreButton />
    </Stack>
  );
};
